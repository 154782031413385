import {
  EntityListField,
  PERMISSION_ACCESS,
  PERMISSION_CREATE,
  PERMISSION_DELETE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@do/app-common';
import { FieldType, FilterType } from '@do/common-interfaces';
import { marker } from '@do/ngx-translate-extract-marker';

export const ENTITY = marker('cinema_room');

export const PERMISSION_ACCESS_ENTITY = `${PERMISSION_ACCESS}_${ENTITY}`;
export const PERMISSION_CREATE_ENTITY = `${PERMISSION_CREATE}_${ENTITY}`;
export const PERMISSION_READ_ENTITY = `${PERMISSION_READ}_${ENTITY}`;
export const PERMISSION_UPDATE_ENTITY = `${PERMISSION_UPDATE}_${ENTITY}`;
export const PERMISSION_DELETE_ENTITY = `${PERMISSION_DELETE}_${ENTITY}`;

export const getGridColumn = (showCinema: boolean) => {
  const gridColumns: EntityListField[] = [
    {
      elementLabel: marker('Name'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'name',
    },
    {
      elementLabel: marker('MAC Address'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'masterControlUnit.macAddress',
    },
  ];

  if (showCinema) {
    gridColumns.push({
      elementLabel: marker('Cinema'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'cinema.name',
    });
    gridColumns.push({
      elementLabel: marker('Chain'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'cinema.cinemaChain.name',
    });
    gridColumns.push({
      elementLabel: marker('Customer'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'cinema.cinemaChain.customer.name',
    });
  }
  return gridColumns;
};
