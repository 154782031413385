import { firstValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCRUDApiClient } from '@do/app-base-components';
import { CinemaRoomDto } from '@do/common-dto';

import { ApiClientConfig } from '../api-client-config';

@Injectable({ providedIn: 'root' })
export class CinemaRoomApiClient extends BaseCRUDApiClient<CinemaRoomDto> {
  baseUrl = this.config.apiEndpoint + '/cinema-room';

  constructor(http: HttpClient, private config: ApiClientConfig) {
    super(http);
  }

  sendCommand(
    cinemaRoomId: string,
    seats: number[],
    command: string,
    payload: string
  ) {
    return firstValueFrom(
      this.http.post<void>(`${this.baseUrl}/${cinemaRoomId}/send-command`, {
        seats,
        command,
        payload,
      })
    );
  }
}
